export function findParentMenu(menuItem) {
  let parent = menuItem.parentNode;
  let count = 0;
  while (
    (parent.className.indexOf("bp3-menu") === -1 ||
      parent.tagName.toLowerCase() === "body") &&
    count < 1000
  ) {
    parent = parent.parentNode;
    count++;
  }
  if (parent.tagName.toLowerCase() === "body") {
    return menuItem;
  }
  return parent;
}
