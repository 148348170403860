import React, { useState } from "react";
import LeapMenuContext from "./LeapMenuContext";
export default function LeapMenuContextWrapper(props) {
  const { children } = props;
  const [hovered, setHovered] = useState(undefined);
  return (
    <LeapMenuContext.Provider
      value={{
        hovered,
        onItemHover: event => {
          setHovered(event);
        }
      }}
    >
      {children}
    </LeapMenuContext.Provider>
  );
}
