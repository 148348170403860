import { useState, useRef, useContext } from "react";
import LeapMenuContext from "./LeapMenuContext";
import { findParentMenu } from "./findParentMenu";

export function useLeapMenuItemHook() {
  const [open, setOpen] = useState(false);
  const menuItemRef = useRef(undefined);
  const context = useContext(LeapMenuContext);
  return { open, setOpen, context, menuItemRef };
}

export function useShouldClose(props) {
  const { setOpen, context, menuItemRef, open } = props;
  if (menuItemRef && menuItemRef.current && context.hovered && open) {
    const parent = findParentMenu(
      menuItemRef.current._reactInternalFiber.child.stateNode
    );
    const { hovered } = context;
    const containsHovered = menuItemRef.current._reactInternalFiber.child.stateNode.contains(
      hovered.current
    );
    const mainParentMenuContainsHovered = parent.contains(hovered.current);
    if (mainParentMenuContainsHovered && !containsHovered) {
      setOpen(false);
    }
  }
}
