import PropTypes from "prop-types";
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { findParentMenu } from "./findParentMenu";
import { useShouldClose, useLeapMenuItemHook } from "./hooks";

export default function LeapMenuItem({ popoverProps, ...props }) {
  const { children } = props;
  const { open, setOpen, context, menuItemRef } = useLeapMenuItemHook();
  useShouldClose({
    open,
    setOpen,
    context,
    menuItemRef
  });

  return (
    <MenuItem
      {...{ ...props }}
      ref={menuItemRef}
      onMouseEnter={() => {
        if (!open) {
          setOpen(true);
          const parent = findParentMenu(
            menuItemRef.current._reactInternalFiber.child.stateNode
          );
          const current =
            menuItemRef.current._reactInternalFiber.child.stateNode;
          context.onItemHover({
            parent,
            current
          });
        }
      }}
      popoverProps={{ ...popoverProps, isOpen: open }}
    >
      {children}
    </MenuItem>
  );
}

LeapMenuItem.propTypes = {
  popoverProps: PropTypes.shape({}).isRequired,
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
LeapMenuItem.defaultProps = {};
