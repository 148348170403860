import React from "react";
import { MenuItem } from "@blueprintjs/core";
import LeapMenuItem from "./LeapMenuItem";

export default function LeapMenuItemSwitch(props) {
  const { children } = props;
  if (!children) {
    return <MenuItem {...{ ...props }} />;
  }
  return <LeapMenuItem {...{ ...props }} />;
}
